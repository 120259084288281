import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthTokens } from '@features/auth';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CookiesModule } from '@paldesk/cookies';
import {
  BASE_PATH as CONFIGURATION_BASE_PATH,
  SlScConfigurationDataAccessModule,
} from '@sales-libs/sc-configuration/data-access';
import {
  BASE_PATH as ONBOARDING_BASE_PATH,
  SlScOnboardingDataAccessModule,
} from '@sales-libs/sc-onboarding/data-access';
import { SlScOnboardingModule } from '@sales-libs/sc-onboarding/feature';
import {
  BASE_PATH as QUESTIONNAIRE_BASE_PATH,
  SlScQuestionnaireDataAccessModule,
} from '@sales-libs/sc-questionnaire/data-access';
import {
  BASE_PATH,
  SlScSolutionsConfDataAccessModule,
} from '@sales-libs/sc-solutions-conf/data-access';
import { BASE_PATH as SUMMARY_BASE_PATH } from '@sales-libs/sc-summary/data-access';
import {
  BASE_PATH as SC_SHARED_BASE_PATH,
  SlSharedScGeneratedDataAccessModule,
} from '@sales-libs/shared/data-access/sc-generated';
import {
  ScSharedEffects,
  ScSharedReducers,
  ScSharedStoreModule,
} from '@sales-libs/shared/feature';
import { MetaTagsService } from '@sales-libs/shared/util';
import {
  GaService,
  GoogleAnalytics4MeasurementId,
  GoogleAnalytics4Module,
  GoogleAnalyticsTrackingId,
} from '@shared-lib/google-analytics';
import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpErrorInterceptor,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import { SharedModule } from '../app/shared/shared.module';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { GeneralOnboardingComponent } from './onboarding/general/general-onboarding.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { ConfigurationGuideResolver } from './shared/resolver/configuration-guide.resolver';
import { SummaryGuideResolver } from './shared/resolver/summary-guide.resolver';

function scBasePathFactory() {
  return environment.palfinger_app_gateway + '/solutions-configurator';
}

@NgModule({
  declarations: [AppComponent, OnboardingComponent, GeneralOnboardingComponent],
  imports: [
    BrowserModule,
    CookiesModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
    HttpClientModule,
    // Store
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      },
    ),
    StoreDevtoolsModule.instrument({
      name: 'Turnkey Solution',
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true,
    }),
    EffectsModule.forRoot(),
    StoreModule.forFeature('ScSharedState', ScSharedReducers.scShared),
    EffectsModule.forFeature([ScSharedEffects]),
    // Shared
    SharedLibFeatTranslationModule,
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
    SharedModule,
    FormsModule,
    GoogleAnalytics4Module.forRoot(
      {
        provide: GoogleAnalytics4MeasurementId,
        useFactory: () => environment.google_analytics_id,
      },
      true,
    ),
    // Sales Libs
    SlScSolutionsConfDataAccessModule,
    SlScOnboardingDataAccessModule,
    SlScOnboardingModule,
    SlScQuestionnaireDataAccessModule,
    SlScConfigurationDataAccessModule,
    ScSharedStoreModule,
    SlSharedScGeneratedDataAccessModule,
  ],
  providers: [
    // HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.POST_LOGOUT_REDIRECT_URI,
    },
    {
      provide: BASE_PATH,
      useFactory: scBasePathFactory,
    },
    // SALES LIB BASE PATHS
    {
      provide: ONBOARDING_BASE_PATH,
      useFactory: scBasePathFactory,
    },
    {
      provide: QUESTIONNAIRE_BASE_PATH,
      useFactory: scBasePathFactory,
    },
    {
      provide: SUMMARY_BASE_PATH,
      useFactory: scBasePathFactory,
    },
    {
      provide: CONFIGURATION_BASE_PATH,
      useFactory: scBasePathFactory,
    },
    {
      provide: SC_SHARED_BASE_PATH,
      useFactory: scBasePathFactory,
    },
    // analytics
    GaService,
    {
      provide: GoogleAnalyticsTrackingId,
      useFactory: () => environment.google_analytics_id,
    },
    ConfigurationGuideResolver,
    SummaryGuideResolver,
    MetaTagsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
