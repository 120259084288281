import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  CommonService,
  ExportService,
  FormService,
  GuideService,
  IdentityService,
  ImportService,
  PdfService,
} from './generated';

@NgModule({
  imports: [CommonModule],
  providers: [
    CommonService,
    ExportService,
    FormService,
    GuideService,
    IdentityService,
    ImportService,
    PdfService,
  ],
})
export class SlScSolutionsConfDataAccessModule {}
