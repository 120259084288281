import { Component, HostListener } from '@angular/core';
import { GoogleAnalytics4Service } from '@shared-lib/google-analytics';

@Component({
  selector: 'tks-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(private _ga4service: GoogleAnalytics4Service) {}

  @HostListener('window:CookieInformationConsentGiven')
  onConsentChange() {
    this._ga4service.handleCookieConsent();
  }
}
